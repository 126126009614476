import URLSearchParams from 'url-search-params'
import { TStatus } from 'types'
import { EXISTING_LANG } from '../../api/constants'

// change this variable to view local build for another brand (or change iFrame src path)
const DEFAULT_BRAND = 'fintech360'

export type TiFrameParams = {
  jwt: string
  lang: string
  redirect_uri: string
  gtm: string
  brand: string
  source: string
  target: string
  status: TStatus
  error_code: string
  signature: string
  theme: string
  currency: string
  method: string
  variable1: string
  variable2: string
  variable3: string
}

const brands = [
  '24xtrade',
  '365trader',
  'bigmarkets',
  'capplace',
  'cuentafx',
  'ellandroadcapital',
  'emarlado',
  'equiity',
  'evostock',
  'finnso',
  'fintana',
  'fintech360',
  'firstecn',
  'fxnovus',
  'fxroad',
  'ifexcapital',
  'igmfx',
  'ltdphoenix',
  'maunto',
  'mirrox',
  'modmountltd',
  'peaksightltd',
  'tradeeu',
  'tradingim',
  'traling',
  'ubuntumarkets',
  'ventorus',
  'xpromarkets',
  'suxxessfx',
  'tradgrip',
  'savexa',
]

const urlSearchParams = new URLSearchParams(window.location.search)
const brandName = window.location.pathname.split('/')[1]
const brand = brands.includes(brandName) ? brandName : DEFAULT_BRAND
const language = urlSearchParams.get('lang') && EXISTING_LANG.includes(urlSearchParams.get('lang') as string)
  ? urlSearchParams.get('lang')?.toLowerCase()
  : 'en'

const iFrameParams: TiFrameParams = {
  jwt: urlSearchParams.get('jwt') ?? '',
  lang: language as string,
  redirect_uri: urlSearchParams.get('redirect_uri') ?? '',
  gtm: urlSearchParams.get('gtm') ?? '',
  brand: brand,
  target: urlSearchParams.get('target') ?? '',
  source: urlSearchParams.get('source') ?? '',
  status: urlSearchParams.get('status') as TStatus ?? '',
  error_code: urlSearchParams.get('error_code') ?? '',
  signature: urlSearchParams.get('signature') ?? '',
  theme: urlSearchParams.get('theme') ?? 'light',
  currency: urlSearchParams.get('currency') ?? 'USD',
  method: urlSearchParams.get('method') ?? '',
  variable1: urlSearchParams.get('variable1') ?? '',
  variable2: urlSearchParams.get('variable2') ?? '',
  variable3: urlSearchParams.get('variable3') ?? '',
}

export default iFrameParams
